import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DialerCampaign } from 'src/app/shared/models';

export const CampaignActions = createActionGroup({
  source: 'Campaign',
  events: {
    'Load Campaigns': props<{ agencyId: string }>(),
    'Load Campaigns Success': props<{ campaigns: DialerCampaign[] }>(),
    'Load Campaigns Failure': props<{ error: any }>(),
    'Load Campaign': props<{ campaignId: string; agencyId: string }>(),
    'Load Campaign Success': props<{ campaign: DialerCampaign }>(),
    'Load Campaign Failure': props<{ error: any }>(),
    'Add Campaign': props<{ campaign: DialerCampaign; agencyId: string }>(),
    'Add Campaign Success': props<{ campaign: DialerCampaign }>(),
    'Add Campaign Failure': props<{ error: any }>(),
    'Remove Campaign': props<{ campaignId: string; agencyId: string }>(),
    'Remove Campaign Success': props<{ campaignId: string }>(),
    'Remove Campaign Failure': props<{ error: any }>(),
    'Update Campaign': props<{
      campaignId: string;
      agencyId: string;
      campaign: Partial<DialerCampaign>;
    }>(),
    'Update Campaign Success': props<{
      campaignId: string;
      agencyId: string;
      campaign: Partial<DialerCampaign>;
    }>(),
    'Update Campaign Failure': props<{ error: any }>(),
  },
});
