import { createFeature, createReducer, on } from '@ngrx/store';
import { CampaignActions } from './campaign.actions';
import { DialerCampaign } from 'src/app/shared/models';

export const campaignFeatureKey = 'campaign';

export interface CampaignState {
  campaign: DialerCampaign | null;
  campaigns: DialerCampaign[];
  loading: boolean;
  error: any;
}

export const initialState: CampaignState = {
  campaign: null,
  campaigns: [],
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    CampaignActions.loadCampaigns,
    (state): CampaignState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    CampaignActions.loadCampaignsSuccess,
    (state, { campaigns }): CampaignState => ({
      ...state,
      campaigns: [...state.campaigns.filter((item) => !campaigns.find(x => x.id === item.id)), ...campaigns],
      loading: false,
      error: null,
    })
  ),
  on(
    CampaignActions.loadCampaignsFailure,
    (state, { error }): CampaignState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    CampaignActions.loadCampaign,
    (state): CampaignState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    CampaignActions.loadCampaignSuccess,
    (state, { campaign }): CampaignState => ({
      ...state,
      campaigns: [...state.campaigns.filter((item) => campaign.id !== item.id), campaign],
      campaign: campaign,
      loading: false,
      error: null,
    }),
  ),
  on(
    CampaignActions.loadCampaignFailure,
    (state, { error }): CampaignState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    CampaignActions.addCampaign,
    (state): CampaignState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    CampaignActions.addCampaignSuccess,
    (state, { campaign }): CampaignState => ({
      ...state,

      campaigns: [...state.campaigns, campaign],

      loading: false,

      error: null,
    }),
  ),
  on(
    CampaignActions.addCampaignFailure,
    (state, { error }): CampaignState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    CampaignActions.removeCampaign,
    (state): CampaignState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    CampaignActions.removeCampaignSuccess,
    (state, { campaignId }): CampaignState => ({
      ...state,
      campaigns: state.campaigns.filter(
        (campaign) => campaign.id !== campaignId,
      ),
      loading: false,
      error: null,
    }),
  ),
  on(
    CampaignActions.removeCampaignFailure,
    (state, { error }): CampaignState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    CampaignActions.updateCampaign,
    (state): CampaignState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    CampaignActions.updateCampaignSuccess,
    (state, { campaign }): CampaignState => ({
      ...state,
      campaigns: state.campaigns.map((item) =>
        // item.id === campaign.id ? campaign : item
        item.id === campaign.id ? { ...item, ...campaign } : item,
      ),
      loading: false,
      error: null,
    }),
  ),
  on(
    CampaignActions.updateCampaignFailure,
    (state, { error }): CampaignState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
);

export const campaignFeature = createFeature({
  name: campaignFeatureKey,
  reducer,
});
